import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { performGetRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { getCmdbObjectSchemaListUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/object-schema.tsx';
import type { ObjectSchema } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-schema.tsx';
import type { ActionApi } from '../../../../common/types.tsx';

export const maxResults = 1000;

type ObjectSchemasResponse = {
	readonly startAt: number;
	readonly maxResults: number;
	readonly total: number;
	readonly isLast: boolean;
	values: ObjectSchema[];
};

export const fetchSchemas =
	(): ActionApi =>
	async ({ setState }, { workspaceId, createAnalyticsEvent }) => {
		try {
			// Currently, Insight returns the list sorted alphabetically by default
			const response: ObjectSchemasResponse = await performGetRequest(
				getCmdbObjectSchemaListUrl(workspaceId, { maxResults }),
			);

			setState({
				schemaState: {
					type: 'success',
					schemas: response.values,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchObjectSchemas succeeded');
		} catch (error: unknown) {
			const typesafeError = error instanceof Error ? error : new Error('Unknown error');
			setState({
				schemaState: { type: 'error', error: typesafeError },
			});
			fireCmdbErrorAnalytics({
				error: typesafeError,
				meta: {
					id: 'fetchSchemas',
					packageName: 'jiraServicedeskInsightLandingPageStore',
					teamName: 'falcons',
				},
			});
		}
	};

import React from 'react';
import Link from '@atlaskit/link';
import EmptyState from '@atlaskit/empty-state';
import CraneIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/other/components/infrastructure-crane/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const contactSupportLink = 'https://support.atlassian.com/contact/#/';

export const MaintenanceModePage = () => {
	const { formatMessage } = useIntl();

	const renderImage = () =>
		getAkEmptyStateRenderImageFn(CraneIllustration)({
			imageWidth: 275,
			maxImageWidth: 275,
			maxImageHeight: 275,
		});

	return (
		<EmptyState
			width="wide"
			renderImage={renderImage}
			header={formatMessage(messages.errorTitle)}
			description={formatMessage(messages.errorDescription, {
				break: () => <br />,
			})}
			primaryAction={
				<Link href={contactSupportLink} target="_blank">
					{formatMessage(messages.contactMessage)}
				</Link>
			}
			testId="cmdb-maintenance-page.ui.cmdb-maintenance-page"
		/>
	);
};

import React, { type ReactNode } from 'react';
import { styled as styled2 } from '@compiled/react';
import { Flex, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const ObjectDetailsSpacer = styled2.div({
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.075', '6px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.075', '6px'),
});

const readViewContainerStyles = xcss({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	lineHeight: `${gridSize * 2.5}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
	minHeight: `${gridSize * 2.25}px`,
	whiteSpace: 'pre-wrap',
	wordWrap: 'break-word',
	flexFlow: 'wrap',
});

const readViewContainerPlaceholderStyles = xcss({
	color: 'color.text.subtlest',
	wordBreak: 'break-word',
	overflowWrap: 'break-word',
});

const readViewContainerNonPlaceholderStyles = xcss({
	wordBreak: 'break-all',
});

/**
 * Note: this container only lays out children but does not provide its own margins / padding - that is for consumers to
 * do, to allow this to be more re-usable. E.g. if this provides 8px padding and consumers want to have 24px, then it
 * gets very fragile having to add 16px (8 + 16 = 24) padding.
 */
export const ReadViewContainer = ({
	children,
	isPlaceholder,
}: {
	children?: ReactNode;
	isPlaceholder?: boolean;
}) => (
	<Flex
		alignItems="center"
		xcss={[
			readViewContainerStyles,
			isPlaceholder ? readViewContainerPlaceholderStyles : readViewContainerNonPlaceholderStyles,
		]}
	>
		{children}
	</Flex>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const RoleList = styled2.div({
	display: 'flex',
	flexWrap: 'wrap',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const RoleContainer = styled2.div({
	display: 'flex',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral', colors.N20),
	borderRadius: '16px',
	paddingTop: token('space.025', '2px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.025', '2px'),
	paddingLeft: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const RoleDisplayName = styled2.div({
	marginLeft: token('space.050', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled,  @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const AvatarWrapper = styled2.div({
	marginLeft: token('space.negative.050', '-4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		verticalAlign: 'bottom',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-styled
export const PlaceholderSpacer = styled2.div({
	paddingTop: token('space.025', '2px'),
	paddingRight: 0,
	paddingBottom: token('space.025', '2px'),
	paddingLeft: 0,
});

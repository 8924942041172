import React, { useMemo } from 'react';
import { SuccessFlag, type FlagProps } from '@atlassian/jira-flags';
import type { FlagCustom } from '@atlassian/jira-flags/src/services/types';
import { useIntl } from '@atlassian/jira-intl';
import type { CompletedBulkExportTask } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { CompletedFlagDescription } from '../completed-flag-description/index.tsx';
import commonMessages from '../messages.tsx';
import type { BaseFlagProps } from '../types.tsx';
import messages from './messages.tsx';

export type CompletedFlagBulkExportProps = {
	task: CompletedBulkExportTask;
} & BaseFlagProps;

export const CompletedFlagBulkExport = ({
	task,
	taskUrl,
	flagProps,
	onDismissed,
}: CompletedFlagBulkExportProps) => {
	const { formatMessage } = useIntl();
	const objectsProcessed = task.resultPayload.objectsProcessed;
	const flagActions: FlagProps['actions'] = useMemo(
		() => [
			{
				content: formatMessage(messages.downloadExport),
				href: taskUrl,

				download: true,
			},
		],
		[formatMessage, taskUrl],
	);
	return (
		<SuccessFlag
			{...flagProps}
			title={formatMessage(messages.flagTitle, {
				objectsProcessed,
			})}
			id={task.taskId}
			description={
				<CompletedFlagDescription task={task}>
					{!task.resultPayload.hasError
						? formatMessage(commonMessages.completedFlagDescription)
						: undefined}
				</CompletedFlagDescription>
			}
			actions={!task.resultPayload.hasError ? flagActions : undefined}
			onDismissed={onDismissed}
			testId="servicedesk-cmdb-async-actions.common.ui.flags.completed-flag-bulk-export"
		/>
	);
};

export const bulkExportFlagHandler = (
	props: Omit<CompletedFlagBulkExportProps, 'flagProps'>,
): FlagCustom => ({
	id: props.task.taskId,
	render: (flagProps) => <CompletedFlagBulkExport {...props} flagProps={flagProps} />,
});

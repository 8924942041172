import React, { useState, useMemo } from 'react';
import { styled as styled2 } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Heading from '@atlaskit/heading';
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add';
import InfoIcon from '@atlaskit/icon/glyph/editor/info';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { AsyncCmdbGettingStarted } from '@atlassian/jira-servicedesk-cmdb-getting-started/src/async.tsx';
import { useInsightGlobalConfig } from '@atlassian/jira-servicedesk-insight-global-configuration-store/src/services/index.tsx';
import { useLandingPageState } from '@atlassian/jira-servicedesk-insight-landing-page-store/src/controllers/store/index.tsx';
import { SchemaCard as InsightSchemaCard } from '@atlassian/jira-servicedesk-insight-schema-card/src/ui/index.tsx';
import type { ObjectSchema } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/object-schema.tsx';
import { isUnexpectedFetchError } from '@atlassian/jira-servicedesk-insight-shared-utils/src/common/utils/use-fetch-error/index.tsx';
import { InsightSchemaContainerSkeleton } from '@atlassian/jira-skeletons/src/ui/insight-landing-container/InsightSchemaContainerSkeleton.tsx';
import { GlobalPageLoadExperience } from '@atlassian/ufo';
import { ErrorContent } from './error/index.tsx';
import { messages } from './messages.tsx';
import type { PropsList } from './types.tsx';

export const SchemaList = (props: PropsList) => {
	const { schemas } = props;

	const listItems = schemas.map((schema, index) => (
		<SchemaCardListItem
			key={index}
			data-testid="servicedesk-insight-schema-container.ui.schema-card-wrapper"
		>
			<InsightSchemaCard schema={schema} />
		</SchemaCardListItem>
	));

	return <SchemaCardList>{listItems}</SchemaCardList>;
};

const InfoTooltip = (props: { content: string }) => (
	<Tooltip
		content={props.content}
		position="top"
		testId="servicedesk-insight-schema-container.ui.schema-limit-tooltip"
	>
		<IconWrapper>
			<InfoIcon size="small" label="" />
		</IconWrapper>
	</Tooltip>
);

export const SchemaContainer = ({
	onSchemaModalStateChange,
}: {
	onSchemaModalStateChange: (isOpen: boolean) => void;
}) => {
	const { formatMessage } = useIntl();
	const [{ schemaState }] = useLandingPageState();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	// Enrich the schema object with the restricted value
	// (to identify the service schema)
	const [{ config: globalConfigData }] = useInsightGlobalConfig();
	const {
		restrictedObjectSchemaIds,
		insightAdministrator: isAdmin,
		limitInfo,
	} = globalConfigData ?? {};

	const objectSchemaList: ObjectSchema[] = useMemo(() => {
		if (schemaState.type !== 'success') {
			return [];
		}

		if (restrictedObjectSchemaIds) {
			return schemaState.schemas.map((schema) => ({
				...schema,
				isRestricted: restrictedObjectSchemaIds.includes(Number(schema.id)),
			}));
		}
		return schemaState.schemas;
	}, [schemaState, restrictedObjectSchemaIds]);

	const openCreateSchemaModal = () => {
		setIsCreateModalOpen(true);
		onSchemaModalStateChange(true);
	};

	const closeCreateSchemaModal = () => {
		setIsCreateModalOpen(false);
		onSchemaModalStateChange(false);
	};

	if (schemaState.type === 'success') {
		GlobalPageLoadExperience.success({ force: true });
	} else if (schemaState.type === 'error') {
		if (isUnexpectedFetchError(schemaState.error, ['5xx'])) {
			GlobalPageLoadExperience.failure({ force: true });
		}
	}

	return (
		<>
			{schemaState.type === 'loading' && <InsightSchemaContainerSkeleton />}

			{(schemaState.type === 'error' || schemaState.type === 'success') && (
				<Box xcss={listHeaderStyles}>
					<Box xcss={secondaryListHeaderStyles}>
						<Heading as="h2" size="xsmall">
							{formatMessage(messages.objectSchemas)}
						</Heading>
						{isAdmin && (
							<>
								<>&nbsp;</>
								<ObjectSchemaCounter>
									{`(${limitInfo?.objectSchemaCount}/${limitInfo?.objectSchemaLimit})`}
								</ObjectSchemaCounter>
							</>
						)}
					</Box>
					<Box xcss={secondaryListHeaderStyles}>
						{isAdmin &&
							(limitInfo?.objectSchemaCount &&
							limitInfo?.objectLimit &&
							limitInfo?.objectSchemaCount >= limitInfo?.objectSchemaLimit ? (
								<Box xcss={schemaLimitLabelStyles}>
									{formatMessage(messages.schemaLimitReached)}
									<InfoTooltip
										content={formatMessage(messages.schemaLimitErrorMessage, {
											limit: limitInfo.objectSchemaLimit,
										})}
									/>
								</Box>
							) : (
								<>
									<Button
										appearance="default"
										spacing="compact"
										onClick={(
											_event: React.MouseEvent<HTMLElement>,
											analyticsEvent: UIAnalyticsEvent,
										) => {
											openCreateSchemaModal();
											fireUIAnalytics(analyticsEvent, 'openCreateSchemaDrawer');
										}}
										iconBefore={<EditorAddIcon label="" size="medium" />}
										testId="servicedesk-insight-schema-container.ui.create-icon-button"
										interactionName="servicedesk-insight-schema-container.ui.create"
									>
										{formatMessage(messages.createSchema)}
									</Button>
								</>
							))}
					</Box>
				</Box>
			)}

			{schemaState.type === 'error' && <ErrorContent />}
			{schemaState.type === 'success' && schemaState.schemas.length > 0 && (
				<SchemaList schemas={objectSchemaList} />
			)}
			<AsyncCmdbGettingStarted isOpen={isCreateModalOpen} onClose={closeCreateSchemaModal} />
		</>
	);
};

const listHeaderStyles = xcss({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: 'space.300',
	width: '100%',
	marginTop: 'space.200',
});

const secondaryListHeaderStyles = xcss({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SchemaCardList = styled2.ul({
	paddingTop: token('space.0', '0px'),
	paddingRight: token('space.0', '0px'),
	paddingBottom: token('space.0', '0px'),
	paddingLeft: token('space.0', '0px'),
	listStyleType: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SchemaCardListItem = styled2.li({
	marginBottom: token('space.100', '8px'),
});

const schemaLimitLabelStyles = xcss({
	display: 'flex',
	font: token('font.body.small'),
	height: '24px',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IconWrapper = styled2.div<{ isDisabled?: boolean }>({
	display: 'flex',
	paddingTop: 0,
	paddingRight: token('space.050', '4px'),
	paddingBottom: 0,
	paddingLeft: token('space.050', '4px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	cursor: (props) => (props.isDisabled ? 'not-allowed' : 'inherit'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ObjectSchemaCounter = styled2.span({
	font: token('font.body.small'),
});

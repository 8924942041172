import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	flagTitle: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-qr-code.flag-title',
		defaultMessage: `{objectsProcessed, plural,
    		=0 {Action complete: Print QR code}
    		one {Action complete: Print QR code for {objectsProcessed} object}
    		other {Action complete: Print QR code for {objectsProcessed} objects}
		}`,
		description: 'Flag title when QR code action is completed',
	},
	downloadPdf: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-qr-code-flag.download-pdf',
		defaultMessage: 'Download QR Codes',
		description: 'Flag action to download PDF',
	},
});

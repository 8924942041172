import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	OPERATION_TYPE_BULK_DELETE,
	STATUS_QUEUED,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/task.tsx';
import { mergeTasks } from '../../../common/utils/merge-tasks/index.tsx';
import { createAsyncBulkDeleteTask } from '../../services/create-async-bulk-delete-task/index.tsx';
import type { AsyncBulkDeleteParams } from '../../services/create-async-bulk-delete-task/types.tsx';
import type { CmdbAsyncActionStoreAction } from '../../types.tsx';
import { showErrorFlag } from '../show-error-flag/index.tsx';
import { showSuccessFlag } from '../show-success-flag/index.tsx';
import { startTaskPolling } from '../start-task-polling/index.tsx';

export type SubmitBulkDeleteTaskProps = {
	objectCount: string;
} & AsyncBulkDeleteParams;

export const submitBulkDeleteTask =
	({
		objectCount,
		...body
	}: SubmitBulkDeleteTaskProps): CmdbAsyncActionStoreAction<Promise<void>> =>
	async ({ setState, getState, dispatch }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().bulkDelete.isSubmitting) {
			return;
		}
		setState({ bulkDelete: { isSubmitting: true } });

		try {
			const { taskId } = await createAsyncBulkDeleteTask(workspaceId, { ...body });

			setState({
				tasks: mergeTasks(getState().tasks, [
					{
						taskId,
						operationType: OPERATION_TYPE_BULK_DELETE,
						status: STATUS_QUEUED,
					},
				]),
				bulkDelete: { isSubmitting: false },
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'submitAsyncBulkDeleteTask succeeded');
			dispatch(
				showSuccessFlag({
					id: taskId,
					objectCount,
					action: OPERATION_TYPE_BULK_DELETE,
				}),
			);
			dispatch(startTaskPolling());
		} catch (error) {
			fireCmdbErrorAnalytics({
				error: error instanceof Error ? error : undefined,
				meta: {
					id: 'submitAsyncBulkDeleteTask',
					packageName: 'jiraServicedeskCmdbAsyncActions',
					teamName: 'falcons',
				},
			});

			setState({ bulkDelete: { isSubmitting: false } });

			dispatch(showErrorFlag({ action: OPERATION_TYPE_BULK_DELETE, objectCount }));

			throw error;
		}
	};

import React, { type ReactNode } from 'react';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { JSErrorPageAsync } from '@atlassian/jira-error-pages/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MaintenanceModePage } from '@atlassian/jira-cmdb-maintenance-page/src/ui/index.tsx';
import type { ContainerProps } from '../controllers/types.tsx';
import { CmdbArchitectureStoreContainer, useCmdbArchitectureStore } from '../controllers/index.tsx';

type CmdbArchitectureContainer = Pick<ContainerProps, 'workspaceId'> & { children?: ReactNode };

export const CmdbArchitectureContainer = ({ workspaceId, children }: CmdbArchitectureContainer) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return fg('assets_rearch_migration') ? (
		<CmdbArchitectureStoreContainer
			isGlobal
			workspaceId={workspaceId}
			createAnalyticsEvent={createAnalyticsEvent}
		>
			<MigrationHandler>{children}</MigrationHandler>
		</CmdbArchitectureStoreContainer>
	) : (
		children
	);
};

// Only exported for testing purposes
export const MigrationHandler = ({ children }: { children?: ReactNode }) => {
	const { loading, error, migrationStatus } = useCmdbArchitectureStore();

	if (error) {
		return <JSErrorPageAsync />;
	}

	if (loading || !migrationStatus) {
		return null;
	}

	if (migrationStatus.blockWriteTraffic) {
		return <MaintenanceModePage />;
	}

	return children;
};

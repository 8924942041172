import React, { type ReactNode, type ComponentType, type FC } from 'react';
import { styled } from '@compiled/react';
import PageDI from '@atlaskit/page';
import { token } from '@atlaskit/tokens';

type Props = {
	children: ReactNode;
	Page?: ComponentType<{
		children: ReactNode;
	}>;
};

export const PageStyle: FC<Props> = ({ Page = PageDI, children }) => (
	<JSDSpaPageWrapper>
		<Page>{children}</Page>
	</JSDSpaPageWrapper>
);

export default PageStyle;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const JSDSpaPageWrapper = styled.div({
	marginTop: 0,
	marginRight: token('space.500', '40px'),
	marginBottom: 0,
	marginLeft: token('space.500', '40px'),
});

import { fetchMigrationStatus } from '../../actions/fetch-migration-status/fetchMigrationStatus.tsx';
import type { CmdbArchitectureStoreAction } from '../../types.tsx';

export const fetchInitialStatus =
	(): CmdbArchitectureStoreAction =>
	({ getState, dispatch }) => {
		if (!getState().loading && !getState().migrationStatus) {
			dispatch(fetchMigrationStatus());
		}
	};

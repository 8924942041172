import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	SchemaId,
	CmdbObjectId,
	CmdbObjectTypeId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbAnalyticAttributesFromRestrictedSchemaIds } from '@atlassian/jira-servicedesk-cmdb-analytics/src/index.tsx';

export const fireObjectClickedUiEvent = ({
	analyticsEvent,
	objectId,
	schemaId,
	restrictedObjectSchemaIds,
}: {
	analyticsEvent: UIAnalyticsEvent;
	objectId: CmdbObjectId;
	schemaId: SchemaId;
	restrictedObjectSchemaIds: SchemaId[];
}) => {
	fireUIAnalytics(analyticsEvent, 'link clicked', 'insightRapidSearchResult', {
		searchResultType: 'object',
		objectId,
		...getCmdbAnalyticAttributesFromRestrictedSchemaIds(restrictedObjectSchemaIds, schemaId),
	});
};

export const fireObjectTypeClickedUiEvent = ({
	analyticsEvent,
	objectTypeId,
	schemaId,
	restrictedObjectSchemaIds,
}: {
	analyticsEvent: UIAnalyticsEvent;
	objectTypeId: CmdbObjectTypeId;
	schemaId: SchemaId;
	restrictedObjectSchemaIds: SchemaId[];
}) => {
	fireUIAnalytics(analyticsEvent, 'link clicked', 'insightRapidSearchResult', {
		searchResultType: 'objectType',
		objectTypeId,
		...getCmdbAnalyticAttributesFromRestrictedSchemaIds(restrictedObjectSchemaIds, schemaId),
	});
};

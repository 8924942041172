import messages from './messages.tsx';

export const RESULTS_MODAL_WIDTH = 'x-large';

export const BULK_EDIT_COLUMNS = [
	messages.affectedObjectsHeader,
	messages.attributesEditedHeader,
	messages.actionPerformedHeader,
	messages.errorsHeader,
];

import { createContainer, createStateHook, createStore } from '@atlassian/react-sweet-state';
import type { ContainerProps, State } from './types.tsx';
import { fetchInitialStatus } from './handlers/fetch-initial-status/fetchInitialStatus.tsx';

export const initialState: State = {
	loading: false,
	error: undefined,
	migrationStatus: undefined,
};

const store = createStore({
	initialState,
	actions: {},
});

export const CmdbArchitectureStoreContainer = createContainer<State, {}, ContainerProps>(store, {
	onInit: fetchInitialStatus,
});

export const useCmdbArchitectureStore = createStateHook(store);

export const useIsNewCmdbArchitecture = createStateHook(store, {
	selector: ({ migrationStatus }) => migrationStatus?.routeToTempeh ?? false,
});

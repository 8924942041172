import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbRestBaseUrl } from './index.tsx';

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/tempeh
 */
export const getCmdbTempehUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbRestBaseUrl(workspaceId)}/tempeh`;

/**
 * /gateway/api/jsm/assets/workspace/${workspaceId}/v1/tempeh/migration
 */
export const getCmdbTempehMigrationUrl = (workspaceId: WorkspaceId) =>
	`${getCmdbTempehUrl(workspaceId)}/migration`;

import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	errorTitle: {
		id: 'servicedesk-cmdb-cmdb-maintenance-page.maintenance-error.error-title',
		defaultMessage: 'Be right back',
		description:
			'Error message describing that the Assets functionality is temporarily unavailable and will be back soon',
	},
	errorDescription: {
		id: 'servicedesk-cmdb-cmdb-maintenance-page.maintenance-error.error-description',
		defaultMessage:
			'Assets is currently under maintenance and will return shortly. <break></break> Please try again later.',
		description:
			'Error description that the Assets feature is currently undergoing maintenance and will be available at a later time',
	},
	contactMessage: {
		id: 'servicedesk-cmdb-cmdb-maintenance-page.maintenance-error.contact-message',
		defaultMessage: 'Contact support',
		description: 'Link for user to contact customer support',
	},
});

import React, { type ReactNode, type KeyboardEventHandler, forwardRef } from 'react';
import Button from '@atlaskit/button';
import DropdownMenu, { DropdownItemGroup } from '@atlaskit/dropdown-menu';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';

export type MeatballsMenuProps = {
	id?: string;
	tabIndex?: number;
	onKeyDown?: KeyboardEventHandler<HTMLButtonElement>;
	isDisabled?: boolean;
	children: ReactNode;
	moreIconLabel: string;
	position?: string;
	testId?: string;
	isOpen?: boolean;
};

const MeatballsMenuNoRef = ({
	id,
	tabIndex,
	onKeyDown,
	moreIconLabel,
	children,
	isDisabled = false,
	testId,
	...restProps
}: MeatballsMenuProps) => (
	<DropdownMenu
		placement="bottom-end"
		trigger={({ triggerRef, ...props }) => (
			<Button
				{...props}
				id={id}
				testId={testId}
				tabIndex={tabIndex}
				iconBefore={<MoreIcon label={moreIconLabel} size="medium" />}
				ref={triggerRef}
				appearance="subtle"
				isDisabled={isDisabled}
				onKeyDown={onKeyDown}
			/>
		)}
		{...restProps}
	>
		<DropdownItemGroup>{children}</DropdownItemGroup>
	</DropdownMenu>
);

const MeatballsMenuForwardRef = forwardRef(
	(
		{
			id,
			tabIndex,
			onKeyDown,
			moreIconLabel,
			children,
			isDisabled = false,
			testId,
			...restProps
		}: MeatballsMenuProps,
		ref,
	) => (
		<DropdownMenu
			placement="bottom-end"
			trigger={({ triggerRef, ...props }) => (
				<Button
					{...props}
					id={id}
					testId={testId}
					tabIndex={tabIndex}
					iconBefore={<MoreIcon label={moreIconLabel} size="medium" />}
					ref={mergeRefs(triggerRef, ref)}
					appearance="subtle"
					isDisabled={isDisabled}
					onKeyDown={onKeyDown}
				/>
			)}
			{...restProps}
		>
			<DropdownItemGroup>{children}</DropdownItemGroup>
		</DropdownMenu>
	),
);

export const MeatballsMenu = componentWithCondition(
	() => fg('assets-a11y-fix'),
	MeatballsMenuForwardRef,
	MeatballsMenuNoRef,
);

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	completedFlagDescription: {
		id: 'servicedesk-cmdb-async-actions.common.completed-flag-description',
		defaultMessage: 'This action was completed successfully',
		description: 'Flag description after completing bulk async task successfully',
	},
	completedFlagErrorDescription: {
		id: 'servicedesk-cmdb-async-actions.common.completed-flag-error-description',
		defaultMessage:
			'Use the link below to check the outcome of your bulk action and view the objects that encountered an error',
		description: 'Flag description after completing bulk async task successfully',
	},
	completedFlagViewReport: {
		id: 'servicedesk-cmdb-async-actions.common.flags.completed-flag-view-report',
		defaultMessage: 'View report',
		description: 'Flag action to view report',
	},
});

/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { styled, jsx } from '@compiled/react';
import EmptyState from '@atlaskit/empty-state';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';

import { token } from '@atlaskit/tokens';

import VisuallyHidden from '@atlaskit/visually-hidden';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import NoSearchResultsIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/empty/components/empty-no-search-results-v2/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { rapidSearchExperience } from '@atlassian/jira-servicedesk-insight-experiences/src/index.tsx';
import type { SchemaId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import {
	createObjectDetailUrl,
	createObjectTypeUrl,
} from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { MIN_QUERY_CHARS } from '../../common/constants.tsx';
import type { Item, SearchState, SearchResult } from '../../common/types.tsx';
import { fireObjectClickedUiEvent, fireObjectTypeClickedUiEvent } from '../../common/utils.tsx';
import { DropdownContainer } from './dropdown-container/index.tsx';
import { ErrorContent } from './error/index.tsx';
import messages from './messages.tsx';

const NoOptionsMessage = () => {
	const { formatMessage } = useIntl();

	const renderImage = () =>
		getAkEmptyStateRenderImageFn(NoSearchResultsIllustration)({
			imageWidth: 208,
			imageHeight: 190,
			maxImageWidth: 208,
			maxImageHeight: 190,
		});

	return (
		<EmptyState
			header={formatMessage(messages.noResultsFoundMessage)}
			renderImage={renderImage}
			testId="servicedesk-insight-rapid-search-bar.ui.popup-content.empty-state"
		/>
	);
};

const INITIAL_DISPLAYED_OBJECTS = 10;
const INITIAL_DISPLAYED_OBJECT_TYPES = 5;

export const getExpand = ({
	selectedIndex,
	numObjects,
}: {
	selectedIndex: number | null;
	numObjects: number;
}): 'objects' | 'object-types' | null => {
	if (selectedIndex === null) {
		return null;
	}
	if (selectedIndex >= numObjects + INITIAL_DISPLAYED_OBJECT_TYPES) {
		return 'object-types';
	}
	if (selectedIndex >= INITIAL_DISPLAYED_OBJECTS && selectedIndex < numObjects) {
		return 'objects';
	}
	return null;
};

export const LoadedContent = ({
	searchResult,
	selectedIndex,
	restrictedObjectSchemaIds,
	onResultClick,
}: {
	searchResult: SearchResult;
	selectedIndex: number | null;
	restrictedObjectSchemaIds: SchemaId[];
	onResultClick: () => void;
}) => {
	const { formatMessage } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const { objects, objectTypes } = searchResult;
	const [displayAllObjects, setDisplayAllObjects] = useState(
		objects.length <= INITIAL_DISPLAYED_OBJECTS,
	);
	const [displayAllObjectTypes, setDisplayAllObjectTypes] = useState(
		objectTypes.length <= INITIAL_DISPLAYED_OBJECT_TYPES,
	);
	useEffect(() => {
		// Any time the selected index moves, potentially expand the objects or object types
		const listToExpand = getExpand({ selectedIndex, numObjects: objects.length });
		if (listToExpand === 'objects') {
			setDisplayAllObjects(true);
		} else if (listToExpand === 'object-types') {
			setDisplayAllObjectTypes(true);
		}
	}, [selectedIndex, objects.length, setDisplayAllObjects, setDisplayAllObjectTypes]);
	const displayedObjects = displayAllObjects
		? objects
		: objects.slice(0, INITIAL_DISPLAYED_OBJECTS);
	const displayedObjectTypes = displayAllObjectTypes
		? objectTypes
		: objectTypes.slice(0, INITIAL_DISPLAYED_OBJECT_TYPES);

	return (
		<DropdownWrapper>
			<DropdownContainer
				groupTitle={formatMessage(messages.objects)}
				items={displayedObjects.map<Item>((object, index) => {
					const { id, label, objectKey, objectSchemaName, objectSchemaId } = object;
					const { url48, mediaClientConfig } = object.avatar;
					return {
						url: createObjectDetailUrl(id),
						id,
						label,
						objectKey,
						iconUrl: url48,
						mediaClientConfig,
						schemaName: objectSchemaName,
						isSelected: selectedIndex === index,
						onClick: () => {
							onResultClick();
							fireObjectClickedUiEvent({
								analyticsEvent: createAnalyticsEvent({}),
								objectId: id,
								schemaId: objectSchemaId,
								restrictedObjectSchemaIds,
							});
						},
					};
				})}
				emptyMessage={formatMessage(messages.noneFound)}
				canShowMore={!displayAllObjects}
				onShowMore={() => {
					setDisplayAllObjects(true);
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						'insightRapidSearchResultViewMore',
						{ searchResultType: 'object' },
					);
				}}
			/>

			<DropdownContainer
				groupTitle={formatMessage(messages.objectTypes)}
				items={displayedObjectTypes.map<Item>((objectType, index) => {
					const { id, name, icon, objectSchemaId, objectSchemaName } = objectType;
					return {
						url: createObjectTypeUrl(objectSchemaId, id),
						id,
						label: name,
						iconUrl: icon.url48,
						schemaName: objectSchemaName,
						isSelected: selectedIndex === objects.length + index,
						onClick: () => {
							onResultClick();
							fireObjectTypeClickedUiEvent({
								analyticsEvent: createAnalyticsEvent({}),
								objectTypeId: objectType.id,
								schemaId: objectSchemaId,
								restrictedObjectSchemaIds,
							});
						},
					};
				})}
				emptyMessage={formatMessage(messages.noneFound)}
				canShowMore={!displayAllObjectTypes}
				onShowMore={() => {
					setDisplayAllObjectTypes(true);
					fireUIAnalytics(
						createAnalyticsEvent({}),
						'button clicked',
						'insightRapidSearchResultViewMore',
						{ searchResultType: 'objectType' },
					);
				}}
			/>
		</DropdownWrapper>
	);
};

export const PopupContentOld = ({
	query,
	searchState,
	selectedIndex,
	restrictedObjectSchemaIds,
	onResultClick,
}: {
	query: string;
	searchState: SearchState;
	selectedIndex: number | null;
	restrictedObjectSchemaIds: SchemaId[];
	onResultClick: () => void;
}) => {
	const { formatMessage } = useIntl();

	if (searchState.type === 'loading')
		return (
			<StyledSpinner>
				<Spinner size="large" />
			</StyledSpinner>
		);

	if (searchState.type === 'error') {
		rapidSearchExperience.failure();
		return <ErrorContent />;
	}

	if (query.trim().length < MIN_QUERY_CHARS) {
		return (
			<QueryTooShortMessage>{formatMessage(messages.typeMoreToSeeResults)}</QueryTooShortMessage>
		);
	}

	rapidSearchExperience.success();

	const { searchResult } = searchState;
	if (searchResult.objects.length === 0 && searchResult.objectTypes.length === 0) {
		return <NoOptionsMessage />;
	}

	return (
		<LoadedContent
			searchResult={searchState.searchResult}
			selectedIndex={selectedIndex}
			restrictedObjectSchemaIds={restrictedObjectSchemaIds}
			onResultClick={onResultClick}
		/>
	);
};

export const PopupContentNew = ({
	query,
	searchState,
	selectedIndex,
	restrictedObjectSchemaIds,
	onResultClick,
}: {
	query: string;
	searchState: SearchState;
	selectedIndex: number | null;
	restrictedObjectSchemaIds: SchemaId[];
	onResultClick: () => void;
}) => {
	const { formatMessage } = useIntl();

	let content;
	let screenReaderMessage = '';

	if (searchState.type === 'loading') {
		content = (
			<StyledSpinner>
				<Spinner size="large" />
			</StyledSpinner>
		);
	} else if (searchState.type === 'error') {
		rapidSearchExperience.failure();
		content = <ErrorContent />;
		screenReaderMessage = formatMessage(messages.errorOccurredScreenReaderMessage);
	} else if (query.trim().length < MIN_QUERY_CHARS) {
		content = (
			<QueryTooShortMessage>{formatMessage(messages.typeMoreToSeeResults)}</QueryTooShortMessage>
		);
		screenReaderMessage = formatMessage(messages.typeMoreToSeeResults);
	} else {
		rapidSearchExperience.success();
		const { searchResult } = searchState;
		screenReaderMessage = `${formatMessage(messages.objectSearchResultsScreenReaderMessage, {
			objectCount: searchResult.objects?.length ?? 0,
		})} ${formatMessage(messages.objectTypeSearchResultsScreenReaderMessage, {
			objectTypeCount: searchResult.objectTypes?.length ?? 0,
		})}`;
		if (searchResult.objects.length === 0 && searchResult.objectTypes.length === 0) {
			content = <NoOptionsMessage />;
		} else {
			content = (
				<LoadedContent
					searchResult={searchState.searchResult}
					selectedIndex={selectedIndex}
					restrictedObjectSchemaIds={restrictedObjectSchemaIds}
					onResultClick={onResultClick}
				/>
			);
		}
	}

	return (
		<>
			<VisuallyHidden aria-live="polite" role="status">
				{screenReaderMessage}
			</VisuallyHidden>
			{content}
		</>
	);
};

export const PopupContent = componentWithCondition(
	() => fg('assets-a11y-fix'),
	PopupContentNew,
	PopupContentOld,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownWrapper = styled.div({
	display: 'flex',
	flexDirection: 'column',
	paddingTop: token('space.100', '8px'),
	paddingRight: token('space.100', '8px'),
	paddingBottom: token('space.100', '8px'),
	paddingLeft: token('space.100', '8px'),
	gap: token('space.100', '8px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	maxHeight: `${gridSize * 70}px`,
	overflowY: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledSpinner = styled.div({
	paddingTop: token('space.200', '16px'),
	display: 'flex',
	justifyContent: 'center',
	minHeight: '300px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const QueryTooShortMessage = styled.div({
	paddingTop: token('space.500', '40px'),
	paddingRight: 0,
	paddingBottom: token('space.500', '40px'),
	paddingLeft: 0,
	textAlign: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N300),
});

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	affectedObjectsHeader: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.affected-objects-header',
		defaultMessage: 'Affected object',
		description: 'Affected object table column header',
	},
	attributesEditedHeader: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.attribute-edited-header',
		defaultMessage: 'Attribute edited',
		description: 'Attribute edited table column header',
	},
	actionPerformedHeader: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.action-performed-header',
		defaultMessage: 'Action performed',
		description: 'Action performed table column header',
	},
	errorsHeader: {
		id: 'servicedesk-cmdb-async-actions-results-modal.common.errors-header',
		defaultMessage: 'Error',
		description: 'Error table header',
	},
});

import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import type { WorkspaceId } from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { getCmdbTempehMigrationUrl } from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/tempeh.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { CmdbArchitectureStoreAction } from '../../types.tsx';

type TempehMigrationResponse = {
	blockWriteTraffic: boolean;
	routeToTempeh: boolean;
};

export const getMigrationStatus = async (workspaceId: WorkspaceId) =>
	performCmdbGetRequest<TempehMigrationResponse>(getCmdbTempehMigrationUrl(workspaceId));

export const fetchMigrationStatus =
	(): CmdbArchitectureStoreAction<void> =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		if (getState().loading) {
			return;
		}
		setState({ loading: true });

		try {
			const { blockWriteTraffic, routeToTempeh } = await getMigrationStatus(workspaceId);

			fireTrackAnalytics(createAnalyticsEvent({}), 'fetchMigrationStatus succeeded');

			setState({
				loading: false,
				migrationStatus: {
					blockWriteTraffic,
					routeToTempeh,
				},
			});
		} catch (error) {
			const typedError = error instanceof Error ? error : undefined;
			// We intentionally don't use fireCmdbErrorAnalytics because it's dependent on this store
			fireErrorAnalytics({
				error: typedError,
				meta: {
					id: 'fetchMigrationStatus',
					packageName: 'jiraServicedeskCmdbArchitecture',
					teamName: 'falcons',
				},
			});
			setState({ loading: false, error: typedError });
		}
	};
